import { Typography, useTheme } from "@mui/material";
import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService"
import { CustomBox } from "../../../../../../Layout/CustomBox"
import { useAppSelector } from "../../../../../../Infrastructure/Store/hooks";
import { CopyChip } from "../../../../../../Layout/CopyChip";

export interface PrereleaseCreateDoneProps {
    DownloadUrl: string,
    DownloadValidUntil: Date,
}

export const PrereleaseCreateDone = ({ DownloadUrl, DownloadValidUntil }: PrereleaseCreateDoneProps) => {
    const theme = useTheme();
    const langIsDe = useAppSelector(store => store.settings.language === "de");

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <CustomBox elevation={0} sx={{
                        minHeight: 200,
                        height: '100%',
                        backgroundColor: 'transparent',
                        border: `1px solid ${theme.palette.cardBorder.main}`,
                        backgroundSize: 'cover',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <CopyChip
                            label={getTranslatedText("CopyToClipboard")}
                            isCopyEnabled={true}
                            copyText={getTranslatedText("PrereleaseCreateDoneResponseMessage",
                                [
                                    "myDEPRAG",
                                    DownloadUrl,
                                    new Date(DownloadValidUntil).toLocaleDateString(langIsDe ? 'DE-de' : 'EN-us')
                                ]).replace(/<\/?[^>]+(>|$)/g, "")}
                                sx={{width:'fit-content', alignSelf:'end'}} />
                        <Typography >
                            <div
                                dangerouslySetInnerHTML={{ __html: getTranslatedText("PrereleaseCreateDoneResponseMessage", ["myDEPRAG", DownloadUrl, new Date(DownloadValidUntil).toLocaleDateString(langIsDe ? 'DE-de' : 'EN-us')]) }}
                            />
                        </Typography>
                    </CustomBox>
                </>
            }
        </LanguageConsumer>
    )
}